<template>
  <Layout>
    <Header class="header">
      <Breadcrumb class="breadcrumb">
        <BreadcrumbItem to="/sys_manage/role">系统角色</BreadcrumbItem>
        <BreadcrumbItem>关联资源</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Content class="content">
      <Spin size="large" v-if="loading" fix></Spin>
      <div style="margin-bottom: 20px;color: #0077EE;">
        <label>角色名称：</label><label>{{role.roleName}}</label>
        <label style="margin-left: 20px;">角色简介：</label><label>{{role.roleDesc}}</label>
      </div>
      <Card style="width:600px" >
        <CheckboxGroup v-model="resIdList">
          <template v-for="item in resList">
            <Checkbox :label="item.id" :key="item.id">
              <span>{{item.resName}}</span>
            </Checkbox>
          </template>
        </CheckboxGroup>
        <Button style="background: #00A0E9;" class="btn" type="primary" @click="submit">保存</Button>
      </Card>
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    data() {
        return {
          loading: true,
          id: null,
          resList: [],
          role: {},
          resIdList: [],
        }
    },
    mounted() {
      this.id = this.$route.params.id
      Promise.all([ this.getRole(), this.getResList(), this.getData() ])
      .then(() => this.loading = false)
      .catch(() => this.loading = false)
    },
    methods: {
      getRole() {
        return request.get(`/api/sys/role/${this.id}`)
        .then((data) => this.role = data)
      },
      getResList() {
        return request.get(`/api/sys/res/list`)
        .then((data) => this.resList = data ? data : [])
      },
      getData() {
        return request.get(`/api/sys/role/rel_res_id_list/${this.id}`)
        .then((data) => this.resIdList = data ? data : [])
      },
      submit() {
        const relResIdList = this.resIdList
        request.put(`/api/sys/role/update_rel_res/${this.id}`, relResIdList)
        .then((res) => {
          this.$Message.success('关联成功!')
          this.$router.replace('/sys_manage/role')
        })
      },
    },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
  }
  .btn {
    margin-top: 20px;
  }
 ::v-deep .ivu-card{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-checkbox-group{
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
